import React,{ useState } from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import "./bottomNavigation.css"
import CssBaseline from '@mui/material/CssBaseline';
import {IoIosNotificationsOutline} from "react-icons/io"
import Paper from '@mui/material/Paper';
import {ReactComponent as Home} from "./home.svg"
import {ReactComponent as Cart} from "../NavbarUpper/cart.svg"
import {ReactComponent as Heart} from "../NavbarUpper/heart.svg"
import {ReactComponent as User} from "../NavbarUpper/user.svg"
import { Link } from 'react-router-dom';

export default function SimpleBottomNavigation() {
  const [value, setValue] = useState(0);

  return (
    <Box sx={{ pb: 7 }}>
    <CssBaseline />
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3} className="bottomAppBar">
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction to="/" component={Link} label="Home" icon={<Home/>} />
        <BottomNavigationAction to="/notif" component={Link} label="Notification" icon={<IoIosNotificationsOutline style={{height:"32px",width:"32px"}}/>} />
        <BottomNavigationAction to="/wishlist" component={Link} label="Wishlist" icon={<Heart/>} />
        <BottomNavigationAction to="/cart" component={Link} label="Cart" icon={<Cart/>} />
        <BottomNavigationAction to="/account" component={Link} label="Account" icon={<User />} />
      </BottomNavigation>
    </Paper>
  </Box>
  );
}
