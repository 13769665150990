import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { matchPath, useLocation } from "react-router-dom";
import BottomNavigation from "../Components/global_component/Navbar/BottomNavigation/BottomNavigation";
import NavbarUpper from "../Components/global_component/Navbar/NavbarUpper/NavbarUpper";
import NewLowerNavbar from "../Components/global_component/NewLowerNavbar/NewLowerNavbar";
import BottomNavbar from "../Components/local_component/Homepage/NewComponent/Component1/BottomNavbar/BottomNavbar";
const NavbarCustomRoute = ({categories}) => {
  const dontShowAt = ["/", "/signin", "/signup", "/cart", "/ordersummary", "/payment","/reset-password/:userId/:token", "/buynow", "/invoices/:invoiceId"];
  const dontShowMobileAt = ["/account","/profile","/addresses","/wishlist","/cart","/notif","/orders","/rating-review/:id", "/invoices/:invoiceId"]
  const location = useLocation();
  const [show, setShow] = useState(!dontShowAt.includes(location.pathname));

  let isMobile = useMediaQuery({
    query: "(max-width:576px)",
  });

  useEffect(() => {
    if (dontShowAt.some(path => matchPath({ path }, location.pathname)) || (dontShowMobileAt.some(path => matchPath({ path }, location.pathname)) && isMobile)) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [location.pathname,isMobile]);

  if (show) {
    return (
      <>
        <NavbarUpper categories={categories} />
        {/* <LowerNavbar categories={categories}/> */}
        <NewLowerNavbar categories={categories} />
        {isMobile && <BottomNavigation/>}
      </>
    );
  } else {
    if (location.pathname === "/" && isMobile){
        return <BottomNavbar />
    }
    return <></>;
  }
};

export default NavbarCustomRoute;
